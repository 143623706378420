import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { TextField } from "@/components/text-fields";
import { Typography } from "@/components/typography/typography";
import type { PropsWithTheme } from "@/theme";
import { FONT_WEIGHT, TYPOGRAPHY } from "@/theme";

/* --------- GENERIC SEARCH STYLES --------- */
export const StyledOuterWrapper = styled.div<PropsWithTheme<HTMLDivElement>>`
	padding: 20px;
	position: relative;
	z-index: 1;
	${({ theme: { palette, tokens } }) => css`
		border-radius: ${tokens.borderRadius.xl};
		box-shadow: 0 30px 27px rgba(0, 0, 0, 0.09);
		background-color: ${palette.freeze[0]};
	`};
`;

export const StyledSearchWrapper = styled.div<{ hideButton?: boolean }>`
	display: grid;
	align-items: flex-start;
	grid-template-columns: 1fr;
	gap: 16px;
	${({ theme, hideButton }) => css`
		@media ${theme.mq.m} {
			${!hideButton &&
			`
				grid-template-columns: 1fr auto;
				gap: 12px;
			`}
		}
	`};
`;

export const StyledTextFieldWrapper = styled.div`
	position: relative;
`;

export const StyledTextField = styled(TextField)`
	display: flex;
`;

/* --------- BUY SEARCH PREDICTIONS STYLES --------- */
export const StyledSuggestionsWrapper = styled.div<
	PropsWithTheme<HTMLDivElement> & { limitHeight?: boolean }
>`
	position: absolute;
	top: calc(100% + 8px);
	left: 0;
	right: 0;
	overflow: hidden;
	${({ theme: { mq, palette, tokens }, limitHeight }) => css`
		background: ${palette.freeze[0]};
		border: 1px solid ${palette.freeze[200]};
		border-radius: ${tokens.borderRadius.lg};
		box-shadow: ${tokens.boxShadow.MD};
		color: ${palette.freeze[1000]};
		@media ${mq.m} {
			left: 4px;
			right: 4px;
		}
		${limitHeight &&
		`
			overflow-y: visible;
			max-height: 300px;
		`}
	`};
`;

export const StyledSuggestionsWrapperInner = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	width: 100%;
`;

export const StyledNoResults = styled(Typography)<PropsWithTheme>`
	padding: 4px 16px;
	font-weight: ${FONT_WEIGHT.medium};
	${TYPOGRAPHY.body.LG};
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[1000]};
	`};
`;

export const SearchSuggestion = styled.button<PropsWithTheme<HTMLButtonElement>>`
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	min-height: 52px;
	padding: 4px 16px;
	background: transparent;
	border: none;
	font-weight: ${FONT_WEIGHT.medium};
	text-align: left;
	${TYPOGRAPHY.body.LG};
	${({ theme: { palette } }) => css`
		color: ${palette.freeze[1000]};

		&:focus,
		&:hover,
		&:first-of-type {
			background-color: ${palette.freeze[100]};
		}

		&:active {
			background-color: ${palette.freeze[200]};
		}

		:focus-visible {
			outline: none;
		}
	`};
`;
