import React, { useState } from "react";

import { useTranslation } from "next-i18next";

import { useRouter } from "next/router";

import { FormType } from "@/components/forms/";
import { HighlightInputMatch } from "@/components/highlight-input-match";
import { TRACKING_PAYLOADS } from "@/constants/tracking";
import dirs from "@/contentful/content-model/dirs";
import { Dirs } from "@/contentful/content-model/types";
import { useValuationContext } from "@/context/valuation";
import { useTrackingEvent } from "@/hooks/tag-manager";
import type { Prediction } from "@/pages/api/google-places-autocomplete/types";
import { getUTMParameters } from "@/services/google-analytics/utm";
import { getGeocodeByAddress } from "@/services/google-maps";

import { AddressAutocomplete } from "../..";
import type { OptionComponentProps } from "../../types";

const OptionComponent = ({ query, suggestion }: OptionComponentProps) => {
	return <HighlightInputMatch value={suggestion.description} keyword={query} />;
};

export const ValuationAddressAutocomplete = () => {
	const { locale, push, pathname, asPath } = useRouter();
	const { t } = useTranslation("valuation");
	const trackEvent = useTrackingEvent(TRACKING_PAYLOADS[FormType.VALUATION_FUNNEL][0]);
	const { updateInitiator, updateAddress } = useValuationContext();

	const [errorText, setErrorText] = useState<string>(null);

	const validateAddress = async (address: string) => {
		if (address === "" || address.length < 5) {
			setErrorText(t("valuation:address:errors:address"));
			return false;
		}

		try {
			const geocode = await getGeocodeByAddress(address);

			// Evernest valuations require a street number
			if (!geocode.house_number) {
				setErrorText(t("valuation:address.errors.address"));
				return false;
			}

			const invalidGeocode = Object.entries(geocode).some(
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				([_, value]) => value === undefined
			);

			if (invalidGeocode) {
				setErrorText(t("valuation:address.errors.address"));
				return false;
			}

			setErrorText(null);
			return true;
		} catch (error: unknown) {
			if (error === "ZERO_RESULTS") {
				setErrorText(t("valuation:address.errors.address-country"));
				return false;
			}
		}
	};

	const handleSubmit = async (suggestion: Prediction) => {
		const { description } = suggestion;
		const isAddressValid = await validateAddress(description);

		if (isAddressValid) {
			updateInitiator({ pathname, asPath });
			await updateAddress(description);

			void push(
				`/${dirs[Dirs.valuation].href}/${locale}/property-type`,
				{
					pathname: `/${dirs[Dirs.valuation].dir[locale] as string}/property-type`,
					query: getUTMParameters(),
				},
				{ locale }
			).then(() => {
				trackEvent();
			});
		}
	};

	return (
		<AddressAutocomplete
			buttonText={t("valuation:entry-form.button")}
			errorText={errorText}
			handleButtonClick={handleSubmit}
			handleOptionClick={handleSubmit}
			OptionComponent={OptionComponent}
		/>
	);
};
