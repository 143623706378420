import type { ChangeEvent, KeyboardEvent, ReactNode } from "react";
import React, { Children, cloneElement, isValidElement, useEffect, useRef, useState } from "react";

import { useTranslation } from "next-i18next";
import { useClickAway } from "react-use";

import { Button } from "@/components/buttons";
import { FontWeight, TypographyVariant } from "@/theme";

import MagnifierIcon from "../../../public/assets/svg-icons/i-magnifier.svg";

import {
	StyledOuterWrapper,
	StyledSearchWrapper,
	StyledTextField,
	StyledSuggestionsWrapper,
	StyledSuggestionsWrapperInner,
	StyledNoResults,
	StyledTextFieldWrapper,
} from "./styled";
import type { SearchProps } from "./types";

export const Search = ({
	buttonText,
	children,
	error,
	errorText,
	hideButton,
	label,
	limitHeight,
	name = "search",
	placeholder,
	noSuggestionsFound,
	handleConfirm,
	fetchSuggestions,
}: SearchProps) => {
	const { t } = useTranslation("search");
	const [showDropdown, setShowDropdown] = useState(false);
	const hasChildren = Children.count(children) > 0;
	const dropdownRef = useRef(null);

	const handleFocus = () => {
		setShowDropdown(hasChildren || noSuggestionsFound);
	};

	const handleEscape = () => {
		document.getElementById(name)?.blur();
		setShowDropdown(false);
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const query = event.target.value;
		fetchSuggestions(query);
		setShowDropdown(!!query && (hasChildren || noSuggestionsFound));
	};

	const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
		switch (event.key) {
			case "ArrowDown":
				if (hasChildren) {
					event.preventDefault();
					// ArrowDown from the input sets the focus on the first option
					document
						.querySelector<HTMLButtonElement>(
							"#autocomplete-listbox > div > button:first-of-type"
						)
						?.focus();
				}
				break;
			case "Enter":
				handleConfirm();
				break;
			case "Escape":
				handleEscape();
				break;
		}
	};

	const handleOptionKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
		if (event.key !== "Enter") {
			// Want to maintain user being able to press Enter to trigger button click
			event.preventDefault();
		}

		switch (event.key) {
			case "ArrowDown":
				const nextSibling = event.currentTarget.nextElementSibling as HTMLElement;

				if (nextSibling) {
					nextSibling.focus();
				}

				if (!nextSibling) {
					document
						.querySelector<HTMLButtonElement>(
							"#autocomplete-listbox > div > button:first-of-type"
						)
						?.focus();
				}
				break;
			case "ArrowUp":
				const prevSibling = event.currentTarget.previousElementSibling as HTMLElement;

				if (prevSibling) {
					prevSibling.focus();
				} else {
					const input = document.getElementById(name);
					input.focus();
				}
				break;
			case "Escape":
				handleEscape();
				break;
		}
	};

	/**
	 * Takes the children and extends them with our internal key down listener
	 * to manage keyboard navigation in the list of suggestions. So we don't
	 * always have to copy-paste this logic externally.
	 */
	const extendedChildren = hasChildren
		? Children.map<ReactNode, ReactNode>(children, child => {
				const props = {
					onKeyDown: (event: KeyboardEvent<HTMLButtonElement>) =>
						handleOptionKeyDown(event),
				};

				if (isValidElement(child)) {
					return cloneElement(child, props);
				}
		  })
		: null;

	useClickAway(dropdownRef, () => {
		handleEscape();
	});

	useEffect(() => {
		setShowDropdown(hasChildren || noSuggestionsFound);
	}, [hasChildren, noSuggestionsFound]);

	useEffect(() => {
		if (error) {
			// So that the user can read the TextField error message
			setShowDropdown(false);
		}
	}, [error]);

	return (
		<StyledOuterWrapper>
			<StyledSearchWrapper hideButton={hideButton}>
				<StyledTextFieldWrapper>
					<StyledTextField
						aria-autocomplete="list"
						aria-expanded={showDropdown}
						aria-live="polite"
						aria-owns="autocomplete-listbox"
						autoCapitalize="none"
						autoComplete="off"
						role="combobox"
						error={error}
						errorText={errorText}
						label={label}
						name={name}
						placeholder={placeholder ?? t("search:placeholder.default")}
						startadornment={<MagnifierIcon />}
						onKeyDown={handleInputKeyDown}
						onChange={handleInputChange}
						onFocus={handleFocus}
					/>
					{showDropdown && (
						<StyledSuggestionsWrapper
							id="autocomplete-listbox"
							limitHeight={limitHeight}
							ref={dropdownRef}
							role="listbox"
						>
							<StyledSuggestionsWrapperInner>
								{extendedChildren}
								{noSuggestionsFound && (
									<StyledNoResults
										role="alert"
										variant={TypographyVariant.bodyLG}
										weight={FontWeight.medium}
									>
										{t("search:no-results.found")}
									</StyledNoResults>
								)}
							</StyledSuggestionsWrapperInner>
						</StyledSuggestionsWrapper>
					)}
				</StyledTextFieldWrapper>
				{!hideButton && (
					<Button data-test-id="autocomplete-confirm-button" onClick={handleConfirm}>
						{buttonText ?? t("search:search")}
					</Button>
				)}
			</StyledSearchWrapper>
		</StyledOuterWrapper>
	);
};
