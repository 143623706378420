import React from "react";

import { TypographyVariant } from "@/theme";

import { Typography } from "../typography/typography";

import { HighlightedSearchResult } from "./styled";

export interface HighlightInputMatchProps {
	keyword?: string;
	value?: string;
}

export const HighlightInputMatch = ({ value, keyword }: HighlightInputMatchProps) => {
	const lowerCasedInputValue = keyword.toLowerCase();
	const hitIndex = value.toLocaleLowerCase().indexOf(lowerCasedInputValue);
	const before = value.slice(0, hitIndex);
	const match = value.slice(hitIndex, hitIndex + keyword.length);
	const after = value.slice(hitIndex + keyword.length);

	let matchedSerach;

	if (!value || !keyword) {
		matchedSerach = value;
	}

	// nothing found
	if (hitIndex === -1) {
		matchedSerach = value;
	}

	// only check for a query with at least 3 characters
	if (keyword.length < 3) {
		matchedSerach = value;
	}

	if (match) {
		matchedSerach = (
			<span>
				{before}
				<HighlightedSearchResult>{match}</HighlightedSearchResult>
				{after}
			</span>
		);
	}

	return (
		<Typography tight variant={TypographyVariant.bodyLG}>
			{matchedSerach}
		</Typography>
	);
};
