import React, { useState } from "react";

import { useTranslation } from "next-i18next";
import { useDebouncedCallback } from "use-debounce";

import { useRouter } from "next/router";

import { Search, SearchSuggestion } from "@/components/search";
import type { Data, Prediction } from "@/pages/api/google-places-autocomplete/types";

import type { AddressAutocompleteProps } from "./types";

export const AddressAutocomplete = ({
	buttonText,
	handleButtonClick,
	handleOptionClick,
	OptionComponent,
	errorText,
	placeholderText,
}: AddressAutocompleteProps) => {
	const { locale } = useRouter();
	const { t } = useTranslation(["common", "google-maps-autocomplete", "search"]);

	const [error, setError] = useState(false);
	const [currentQuery, setCurrentQuery] = useState<string>(null);
	const [suggestions, setSuggestions] = useState<Prediction[]>([]);
	const [noSuggestionsFound, setNoSuggestionsFound] = useState(false);

	const fetchAutocompleteResults = async (query: string) => {
		setError(false);

		if (!query || query.length < 3) {
			setSuggestions([]);
			return;
		}

		try {
			const response = await fetch("/api/google-places-autocomplete/", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ locale, query }),
			});

			if (!response.ok) {
				throw new Error("Error fetching from suggestions API route");
			}

			const data: Data = await response.json();

			setSuggestions(data.predictions);
			setNoSuggestionsFound(!data.hasResults);
		} catch (error: unknown) {
			console.error(error);
			setSuggestions([]);
			setNoSuggestionsFound(false);
			setError(true);
		}
	};

	const debouncedCallback = useDebouncedCallback(fetchAutocompleteResults, 300);

	return (
		<Search
			buttonText={buttonText ?? t("search:search")}
			error={Boolean(errorText) ?? error}
			errorText={errorText ?? t("common:generic-error")}
			fetchSuggestions={value => {
				setCurrentQuery(value);
				debouncedCallback(value);
			}}
			name="address-autocomplete"
			noSuggestionsFound={noSuggestionsFound}
			placeholder={placeholderText ?? t("google-maps-autocomplete:placeholder")}
			handleConfirm={() => {
				if (suggestions[0] && handleButtonClick) {
					handleButtonClick(suggestions[0]);
				}
			}}
		>
			{suggestions?.map(suggestion => {
				return (
					<SearchSuggestion
						id={suggestion.placeId}
						key={suggestion.placeId}
						role="option"
						tabIndex={-1}
						type="button"
						onClick={() => {
							void handleOptionClick(suggestion);
						}}
					>
						<OptionComponent query={currentQuery} suggestion={suggestion} />
					</SearchSuggestion>
				);
			})}
		</Search>
	);
};
